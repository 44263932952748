<template>
  <div
    id="app"
    :class="{ 'has-mouse': hasMouse }"
    @touchstart="hasMouse = false"
  >
    <Ribbon />
    <Flipbook
      class="flipbook"
      :pages="pages"
      :pagesHiRes="pagesHiRes"
      :startPage="pageNum"
      v-slot="flipbook"
      ref="flipbook"
      @flip-left-start="onFlipLeftStart"
      @flip-left-end="onFlipLeftEnd"
      @flip-right-start="onFlipRightStart"
      @flip-right-end="onFlipRightEnd"
      @zoom-start="onZoomStart"
      @zoom-end="onZoomEnd"
    >
      <div class="action-bar">
        <left-icon
          class="btn left"
          :class="{ disabled: !flipbook.canFlipLeft }"
          @click="flipLeft"
        />
        <plus-icon
          class="btn plus"
          :class="{ disabled: !flipbook.canZoomIn }"
          @click="zoomIn"
        />
        <span class="page-num">
          Page {{ flipbook.page }} of {{ flipbook.numPages }}
        </span>
        <minus-icon
          class="btn minus"
          :class="{ disabled: !flipbook.canZoomOut }"
          @click="zoomOut"
        />
        <right-icon
          class="btn right"
          :class="{ disabled: !flipbook.canFlipRight }"
          @click="flipRight"
        />
      </div>
    </Flipbook>
    <p class="credit">
      Photos from
      <a href="https://unsplash.com/" target="_blank">Unsplash</a>.
    </p>
  </div>
</template>

<script>
import "vue-material-design-icons/styles.css";
import LeftIcon from "vue-material-design-icons/ChevronLeftCircle";
import RightIcon from "vue-material-design-icons/ChevronRightCircle";
import PlusIcon from "vue-material-design-icons/PlusCircle";
import MinusIcon from "vue-material-design-icons/MinusCircle";
import Flipbook from "flipbook-vue";
import Ribbon from "./Ribbon";

export default {
  components: { Flipbook, LeftIcon, RightIcon, PlusIcon, MinusIcon, Ribbon },
  data() {
    return {
      pages: [],
      pagesHiRes: [],
      hasMouse: true,
      pageNum: null,
    };
  },
  methods: {
    flipLeft() {
      const flipbook = this.$refs.flipbook;
      if (flipbook && flipbook.canFlipLeft) {
        flipbook.flipLeft();
      }
    },
    flipRight() {
      const flipbook = this.$refs.flipbook;
      if (flipbook && flipbook.canFlipRight) {
        flipbook.flipRight();
      }
    },
    zoomIn() {
      const flipbook = this.$refs.flipbook;
      if (flipbook && flipbook.canZoomIn) {
        flipbook.zoomIn();
      }
    },
    zoomOut() {
      const flipbook = this.$refs.flipbook;
      if (flipbook && flipbook.canZoomOut) {
        flipbook.zoomOut();
      }
    },
    onFlipLeftStart(page) {
      console.log("flip-left-start", page);
    },
    onFlipLeftEnd(page) {
      console.log("flip-left-end", page);
      this.pageNum = page;
    },
    onFlipRightStart(page) {
      console.log("flip-right-start", page);
    },
    onFlipRightEnd(page) {
      console.log("flip-right-end", page);
      this.pageNum = page;
    },
    onZoomStart(zoom) {
      console.log("zoom-start", zoom);
    },
    onZoomEnd(zoom) {
      console.log("zoom-end", zoom);
    },
    setPageFromHash() {
      const n = parseInt(window.location.hash.slice(1), 10);
      if (isFinite(n)) this.pageNum = n;
    },
  },
  mounted() {
    window.addEventListener("keydown", (ev) => {
      const flipbook = this.$refs.flipbook;
      if (!flipbook) return;
      if (ev.keyCode == 37 && flipbook.canFlipLeft) flipbook.flipLeft();
      if (ev.keyCode == 39 && flipbook.canFlipRight) flipbook.flipRight();
    });

    setTimeout(() => {
      this.pages = [
        null,
        require("@/assets/images/1.jpg"),
        require("@/assets/images/2.jpg"),
        require("@/assets/images/3.jpg"),
        require("@/assets/images/4.jpg"),
        require("@/assets/images/5.jpg"),
        require("@/assets/images/6.jpg"),
      ];
      this.pagesHiRes = [null];
    }, 1);

    window.addEventListener("hashchange", this.setPageFromHash);
    this.setPageFromHash();
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #4e5455;
  color: #050000;
  overflow: hidden;
}

a {
  color: inherit;
}

.action-bar {
  width: 100%;
  height: 50px;
  /* padding: 10px 0;  */
  display: flex;
  justify-content: center;
  align-items: center;
}

.action-bar .btn {
  font-size: 30px;
  color: #999;
  z-index: 10; /* 确保按钮在其他元素之上 */
}

.action-bar .btn svg {
  bottom: 0;
}

.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}

.has-mouse .action-bar .btn:hover {
  color: #ccc;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}

.action-bar .btn:active {
  filter: none !important;
}

.action-bar .btn.disabled {
  color: #666;
  pointer-events: none;
}

.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}

.flipbook .viewport {
  width: 70vw !important;
  height: calc(100vh - 50px - 40px) !important;
  margin-top: 5px; /* 确保按钮不会被遮挡 */
  /* transform: translateY(
    -10px
  ); /* 往上移动60像素，可以根据需要调整 调整图片与按钮之间的*/
}

.flipbook .bounding-box {
  box-shadow: 0 0 20px #000;
}

.credit {
  font-size: 12px;
  line-height: 20px;
  margin: 10px;
}

@media (max-width: 768px) {
  .flipbook .viewport {
    width: 100vw !important; /* 使 flipbook 适应小屏幕 */
  }
  .action-bar {
    display: flex;
    /*  flex-direction: column;  /* 垂直排列按钮 */
    align-items: center; /* 居中按钮 */
    /*  justify-content: space-around;  /* 使按钮有间距 */
    justify-content: center;
    padding: 10px 0; /* 给容器加一些上下间距 */
    height: 10%; /* 容器高度占满屏幕 */
    max-width: 10px;
  }

  .action-bar .btn {
    left: 220px;
    font-size: 30px;
    padding: 10px; /* 增加按钮的点击区域 */
    margin: 5px; /* 增加按钮之间的间距 */
    z-index: 10; /* 确保按钮位于其他元素之上 */
  }


  /* 控制按钮容器宽度
  .action-bar {    
    width: 100%;      容器宽度占满屏幕 */
  /*    max-width: 20px; /* 限制最大宽度，避免按钮变得过宽 */
  /*  } */
}
</style>
