<template>
  <div id="flipbook" class="book" v-show="!showResult">
    
    <!-- 根据 showResult 控制书本的显示/隐藏 -->
    <!-- 引言页 -->
    <div v-if="currentPage === 0" class="page intro-page">
      <h2>欢迎来到MBTI性格测试</h2>
      <p>在接下来的几道题目中，选择最符合你感受的选项。</p>
      <button @click="goToNextPage(0)">开始测试</button>
    </div>

    <!-- 问题页面 -->
    <div v-for="(question, index) in questions" :key="index" class="page">
      <h3>第{{ question.id }}页</h3> 
      <p>{{ question.title }}</p>
      <div class="option">
        <input
          type="radio"
          :id="'q' + question.id + '-a'"
          :name="'question-' + question.id"
          value="A"
          v-model="answers[question.id]"
          @change="goToNextPage(index)"
        />
        <label :for="'q' + question.id + '-a'">{{ question.optionA }}</label>
      </div>
      <div class="option">
        <input
          type="radio"
          :id="'q' + question.id + '-b'"
          :name="'question-' + question.id"
          value="B"
          v-model="answers[question.id]"
          @change="goToNextPage(index)"
        />
        <label :for="'q' + question.id + '-b'">{{ question.optionB }}</label>
      </div>
    </div>

    <!-- 最后一页（问题和结果合并） -->
    <div v-if="showResult" class="page result-page">
      <div class="result">
        <h2>Test Results</h2>
        <p>{{ resultMessage }}</p>
      </div>
    </div>
  </div>

  <!-- 结果页 -->
  <div v-if="showResult" class="result-page">
    <div class="result">
      <h2>Test Results</h2>
      <p>{{ resultMessage }}</p>
    </div>
  </div>

  <!-- <button class="submit-button" @click="submitAnswers" v-if="!showResult">
    Submit
  </button> -->
  <el-button type="info" round class="submit-button" @click="submitAnswers" v-if="!showResult">Submit</el-button>
  <!-- 提交按钮仅在书本页面显示 -->
</template>

<script>
import $ from "jquery";

import "turn.js";

export default {
  name: "UserCardQuestion",
  data() {
    return {
      questions: [
        {
          id: 1,
          title: "当你某日想去某个地方，你会？",
          optionA: "计划好将做的事情以及何时做",
          optionB: "什么都不想就去",
        },
        {
          id: 2,
          title: "你通常更喜欢以下哪种活动？",
          optionA: "和别人讨论想法",
          optionB: "一个人安静地思考",
        },
        {
          id: 3,
          title: "当面临复杂问题时，你会选择？",
          optionA: "一步步逻辑解决",
          optionB: "根据直觉迅速做出决定",
        },
        {
          id: 4,
          title: "当你某日想去某个地方，你会？",
          optionA: "计划好将做的事情以及何时做",
          optionB: "什么都不想就去",
        },
        {
          id: 5,
          title: "你通常更喜欢以下哪种活动？",
          optionA: "和别人讨论想法",
          optionB: "一个人安静地思考",
        },
        {
          id: 6,
          title: "当面临复杂问题时，你会选择？",
          optionA: "一步步逻辑解决",
          optionB: "根据直觉迅速做出决定",
        },
        // 添加更多问题
      ],
      answers: {},
      useSinglePage: false, // 判断是否使用单页模式
      showResult: false, // 是否展示结果
      resultMessage: "", // 结果信息
    };
  },
  mounted() {
    this.checkPageLayout(); // 检查页面布局
    this.initTurnJs();
  },
  methods: {
    initTurnJs() {
      const flipbook = document.getElementById("flipbook");
      $(flipbook).turn({
        width: 800,
        height: 600,
        autoCenter: true,
        display: this.useSinglePage ? "single" : "double", // 根据条件设置显示模式
        pages: this.questions.length, // 设置问题页面数
      });
    },
    checkPageLayout() {
      if (this.questions.length <= 2) {
        this.useSinglePage = true; // 不足两页时启用单页模式
      } else {
        this.useSinglePage = true; // 启用双页模式
      }
    },
    goToNextPage(currentIndex) {
      if (currentIndex === 0) {
        this.currentPage = 0; // 引言页
      } else if (currentIndex <= this.questions.length) {
        this.currentPage = currentIndex; // 翻到问题页
      } else {
        this.showResultPage(); // 显示结果页
      }
      const flipbook = $("#flipbook");
      flipbook.turn("next"); // 翻到下一页
    },
    submitAnswers() {
      console.log("User answers:", this.answers);
      this.calculateResult();
      this.showResultPage();
    },
    calculateResult() {
        const scores = {
        J: 0,
        P: 0,
        S: 0,
        N: 0,
        E: 0,
        I: 0,
        F: 0,
        T: 0
      };
      // 统计选择 optionA 和 optionB 的题目 ID
      const selectedOptionA = [];
      const selectedOptionB = [];
      for (const [id, answer] of Object.entries(this.answers)) {
        if (answer === "A") {
          selectedOptionA.push(id);
          if (
            id === 1 ||
            id === 4 ||
            id === 8 ||
            id === 11 ||
            id === 15 ||
            id === 18 ||
            id === 22 ||
            id === 25 ||
            id === 30
          )
            scores.J += 1; //如果第一道题选A或者第8道题选A  J+1  (哪些题目选择了X1对应的A )
          if (id === 35 || id === 42 || id === 52 || id === 57) scores.J += 1; //如果第35道题选A或者第42道题选A  J+1  (哪些题目选择了Y1对应的A )
          if (
            id === 63 ||
            id === 74 ||
            id === 76 ||
            id === 79 ||
            id === 82 ||
            id === 85 ||
            id === 88 ||
            id === 91 ||
            id === 93
          )
            scores.J += 1; //如果第63道题选A或者第74道题选A  J+1  (哪些题目选择了Z1对应的A )
          //上述3个if计算的是J=X1+Y1+Z1  （可以一起计算，都是算J的值，只要这些题选A选项，就满足J）

          if (
            id === 2 ||
            id === 5 ||
            id === 9 ||
            id === 12 ||
            id === 16 ||
            id === 19 ||
            id === 23 ||
            id === 27 ||
            id === 29 ||
            id === 30
          )
            scores.S += 1; //如果第2道题选A或者第5道题选A  S+1  (哪些题目选择了X3对应的A )
          if (
            id === 33 ||
            id === 38 ||
            id === 40 ||
            id === 45 ||
            id === 49 ||
            id === 51 ||
            id === 53 ||
            id === 55 ||
            id === 60 ||
            id === 62
          )
            scores.S += 1; //如果第33道题选A或者第38道题选A  S+1  (哪些题目选择了Y3对应的A )
          if (id === 64 || id === 66 || id === 70 || id === 81 || id === 87)
            scores.S += 1; //如果第64道题选A或者第66道题选A  S+1  (哪些题目选择了Z3对应的A )
          //上述3个if计算的是S=X3+Y3+Z3  （可以一起计算，都是算S的值，只要这些题选A选项，就满足S）

          if (
            id === 3 ||
            id === 6 ||
            id === 10 ||
            id === 13 ||
            id === 17 ||
            id === 20 ||
            id === 24 ||
            id === 26
          )
            scores.E += 1;
          if (id === 36 || id === 41 || id === 47 || id === 58) scores.E += 1;
          if (
            id === 68 ||
            id === 72 ||
            id === 75 ||
            id === 77 ||
            id === 80 ||
            id === 83 ||
            id === 86 ||
            id === 89 ||
            id === 92
          )
            scores.E += 1;
          //上述3个if计算的是E=X5+Y5+Z5  （可以一起计算，都是算E的值，只要这些题选A选项，就满足E）

          if (id === 7 || id === 14 || id === 21 || id === 28) scores.F += 1;
          if (
            id === 32 ||
            id === 34 ||
            id === 37 ||
            id === 39 ||
            id === 44 ||
            id === 46 ||
            id === 48 ||
            id === 50 ||
            id === 54 ||
            id === 56 ||
            id === 59 ||
            id === 61
          )
            scores.F += 1;
          if (
            id === 65 ||
            id === 67 ||
            id === 69 ||
            id === 71 ||
            id === 73 ||
            id === 78 ||
            id === 84 ||
            id === 90
          )
            scores.F += 1;
          //上述3个if计算的是F=X7+Y7+Z7  （可以一起计算，都是算F的值，只要这些题选A选项，就满足F）          
        } else if (answer === "B") {
          selectedOptionB.push(id);
                    // 对应B选项的加分方式
          // ... (这里是你原来的计算逻辑)
          // 对应B选项的加分方式
          if (
            id === 1 ||
            id === 4 ||
            id === 8 ||
            id === 11 ||
            id === 15 ||
            id === 18 ||
            id === 22 ||
            id === 27 ||
            id === 29
          )
            scores.P += 1; //如果第一道题选B或者第8道题选B  P+1 (哪些题目选择了X2对应的B )
          if (
            id === 35 ||
            id === 42 ||
            id === 52 ||
            id === 57
          )
            scores.P += 1; //如果第35道题选B或者第42道题选B  P+1  (哪些题目选择了Y2对应的B )
          if (
            id === 63 ||
            id === 74 ||
            id === 76 ||
            id === 79 ||
            id === 82 ||
            id === 85 ||
            id === 88 ||
            id === 91 ||
            id === 93
          )
            scores.P += 1; //如果第63道题选B或者第74道题选B  P+1  (哪些题目选择了Z2对应的B )
          //上述3个if计算的是P=X2+Y2+Z2 （可以一起计算，都是算P的值，只要这些题选B选项，就满足P）

          if (
            id === 2 ||
            id === 5 ||
            id === 9 ||
            id === 12 ||
            id === 16 ||
            id === 19 ||
            id === 23 ||
            id === 27 ||
            id === 29 ||
            id === 30
          )
            scores.N += 1; //如果第2道题选B或者第5道题选B  N+1  (哪些题目选择了X3对应的B )
          if (
            id === 33 ||
            id === 38 ||
            id === 40 ||
            id === 45 ||
            id === 49 ||
            id === 51 ||
            id === 53 ||
            id === 55 ||
            id === 60 ||
            id === 62
          )
            scores.N += 1; //如果第33道题选B或者第38道题选B  N+1  (哪些题目选择了Y3对应的B )
          if (
            id === 64 ||
            id === 66 ||
            id === 70 ||
            id === 81 ||
            id === 87
          )
            scores.N += 1; //如果第64道题选B或者第66道题选B N+1  (哪些题目选择了Z3对应的B )
          //上述3个if计算的是N=X4+Y4+Z4  （可以一起计算，都是算N的值，只要这些题选B选项，就满足N）

          if (
            id === 3 ||
            id === 6 ||
            id === 10 ||
            id === 13 ||
            id === 17 ||
            id === 20 ||
            id === 24 ||
            id === 26
          )
            scores.I += 1;
          if (
            id === 36 ||
            id === 41 ||
            id === 47 ||
            id === 58
          )
            scores.I += 1;
          if (
            id === 68 ||
            id === 72 ||
            id === 75 ||
            id === 77 ||
            id === 80 ||
            id === 83 ||
            id === 86 ||
            id === 89 ||
            id === 92
          )
            scores.I += 1;
          //上述3个if计算的是I=X6+Y6+Z6  （可以一起计算，都是算N的值，只要这些题选B选项，就满足I）

          if (
            id === 7 ||
            id === 14 ||
            id === 21 ||
            id === 28
          )
            scores.T += 1;
          if (
            id === 32 ||
            id === 34 ||
            id === 37 ||
            id === 39 ||
            id === 44 ||
            id === 46 ||
            id === 48 ||
            id === 50 ||
            id === 54 ||
            id === 56 ||
            id === 59 ||
            id === 61
          )
            scores.T += 1;
          if (
            id === 65 ||
            id === 67 ||
            id === 69 ||
            id === 71 ||
            id === 73 ||
            id === 78 ||
            id === 84 ||
            id === 90
          )
            scores.T += 1;
          //上述3个if计算的是T=X8+Y8+Z8  （可以一起计算，都是算T的值，只要这些题选B选项，就满足T）
           
        }
      }
      this.resultMessage =  this.calculateMBTI(scores);
    },
    calculateMBTI(scores) {
      const J_P = ((scores.P - scores.J) / 22) * 10;
      const S_N = ((scores.N - scores.S) / 26) * 10;
      const E_I = ((scores.I - scores.E) / 21) * 10;
      const F_T = ((scores.T - scores.F) / 24) * 10;

      return `${E_I > 0 ? "E" : "I"}${S_N > 0 ? "S" : "N"}${
        F_T > 0 ? "T" : "F"
      }${J_P > 0 ? "P" : "J"}`;
    },
    showResultPage() {
      this.showResult = true; // 显示结果页面
    },
  },
};
</script>

<style scoped>
.book {
  margin: 50px auto;
  width: 800px;
  height: 600px;
}

.page {
  background: #dd0606;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  background: #fff url("../../assets/background-image.jpg") no-repeat center;
}

.page h2 {
  margin-top: 0;
  font-size: 24px;
  
}

.option {
  margin: 10px 0;
}

.submit-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  font-size: 16px;
  color: #41b7cc;
  background-color: #9bfdf8;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.submit-button:hover {
  background-color: #0056b3;
}

.result-page {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.result {
  width: 100%;
  text-align: center;
}

.result h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.result p {
  font-size: 24px;
  color: #333;
}
</style>
